@import "variables";
@import "mixins";

.mvpInput {

  .label {
    margin-bottom: pxToRem(16px);
    display: flex;
    align-items: flex-end;
  }

  .errorMsg {
    flex-grow: 1;
    text-align: right;
    font-size: pxToRem(20px);
    margin-left: pxToRem(15px);
    animation: fadeIn ease 0.6s;
    color: $redColor;
  }

  .inputWrapper {
    background-color: $whiteColor;

    &.error {
      box-shadow: none !important;
      border: 1px solid $redColor;
    }

    &:focus-within {
      transition: 0.1s;

      &.purpleAccent {
        box-shadow: 0 0 3px 0 $purpleColor;
      }

      &.cyanAccent {
        box-shadow: 0 0 3px 0 $cyanColor;
      }

      &.redAccent {
        box-shadow: 0 0 3px 0 $redColor;
      }

      &.blueAccent {
        box-shadow: 0 0 3px 0 $blueColor;
      }
    }
  }

  input, textarea {
    padding: pxToRem(31px) 0 pxToRem(31px) pxToRem(21px);
    border-radius: 0;
    min-height: pxToRem(80px);
    outline: none;
    border: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    overflow-wrap: break-word;

    &::placeholder {
      color: rgba(0,0,0,0.2);
    }

    font-size: pxToRem(25px);
  }

  textarea {
    resize: none;
    padding: pxToRem(20px) 0 pxToRem(20px) pxToRem(20px);
    max-height: pxToRem(80px);
  }
}

@include media-breakpoint-down(lg) {
  .mvpInput {

    .label {
      font-size: pxToRem(16px);
    }

    input, textarea {
      font-size: pxToRem(14px);
      min-height: pxToRem(60px);
      padding: pxToRem(10px) 0 pxToRem(10px) pxToRem(10px);
    }

    textarea {
      padding: pxToRem(20px) 0 pxToRem(15px) pxToRem(20px);
      max-height: pxToRem(60px);
    }

    .errorMsg {
      font-size: pxToRem(16px);
    }
  }
}
