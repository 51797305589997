@import "variables";
@import "mixins";

.tokenCanvasModal {
  .modalHeader {
    width: 100%;
    height: pxToRem(100px);
    background-color: $redColor;
    display: flex;
    justify-content: center;

    .modalHeaderIcon {
      margin-top: pxToRem(10px);
      width: pxToRem(100px);
      height: pxToRem(100px);
    }
  }

  .modalBody {
    padding: pxToRem(80px) pxToRem(60px) pxToRem(12px) pxToRem(60px);

    .modalTitle {
      font-size: pxToRem(32px);
      font-weight: bold;
      line-height: pxToRem(32px);
    }

    .modalDescription {
      font-size: pxToRem(16px);
      line-height: pxToRem(25px);
      font-weight: 300;
      min-height: pxToRem(240px);
    }
  }

  .modalFooter {
    border-top: 0;
    justify-content: space-between;
    padding: pxToRem(12px) pxToRem(60px);
  }

  @include media-breakpoint-down(lg) {
    .modalBody {
      padding: pxToRem(20px) pxToRem(20px) pxToRem(12px);
    }

    .modalFooter {
      padding: pxToRem(12px) pxToRem(20px);

      button {
        padding: 0 !important;
      }
    }
  }
}
