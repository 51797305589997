@import "../../../Common/Styles/variables";
@import "../../../Common/Styles/mixins";

.wizardDonePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../../Common/Assets/pattern.png");

  .wizardDoneContainer {
    @include fullPageContainer;
    padding-left: 0;
    padding-right: 0;
  }

  .finishBtn {
    border: pxToRem(1px) solid #A8B1E9;
    color: #A8B1E9;
  }

  .emailInput {
    align-self: stretch;
    text-align: left;
    margin-bottom: pxToRem(130px);
  }
}
