@import "variables";
@import "mixins";

html, body, #root {
  height: 100%;
  width: 100%;
  background-color: $lightBackgroundColor;

  scrollbar-color: $lightBackgroundColor transparent;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $lightBackgroundColor;
    border-radius: 3px;
  }
}

body {
  font-family: $titilliumWeb;
  font-size: $defaultFontSize;

  .heroHeadline {
    font-size: pxToRem(61.04px);
    font-weight: bold;
    line-height: 67px;
    margin-bottom: pxToRem(25px);

    @include media-breakpoint-down(lg) {
      font-size: pxToRem(32px);
      line-height: pxToRem(43px);
    }
  }

  .descriptionText {
    color: rgba(19,19,21,0.5);
    font-size: pxToRem(31px);
    line-height: pxToRem(47px);
    margin-bottom: pxToRem(45px);

    @include media-breakpoint-down(lg) {
      font-size: pxToRem(16px);
      line-height: pxToRem(24px);
    }
  }

  h1 {
    font-size: 3.052rem;
  }

  h2 {
    font-size: 2.441rem;
  }

  h3 {
    font-size: 1.953rem;
  }

  h4 {
    font-size: 1.563rem;
  }

  h5 {
    font-size: 1.25rem;
  }
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;

  .pages {
    display: flex;
    flex: 1 0 auto;
  }
}

.formContainer {
  @include container;
  align-self: stretch;

  .inputContainer {
    margin-bottom: pxToRem(25px);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
  }
}

.stepBtnContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

button {
  outline: none;
  border: none;
  @include btnPseudoClasses;
}

.fluidSvg {
  height: 100%;
  width: 100%;
  transition: 0.2s;
}

.cusotmToastr {
  .toastr {
    background-color: black !important;
  }
}
