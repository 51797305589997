//Fonts
$titilliumWeb: "Titillium Web";
$defaultFontSize: 16px;

//Colors
$lightBackgroundColor: #EDEFF2;
$darkerBackgroundColor: #131215;
$darkBackgroundColor: #000000;
$descriptionTextColor: #B7B7B8;
$purpleColor: #5C1ECF;
$cyanColor: #00C6BB;
$whiteColor: #FFFFFF;
$redColor: #E61B53;
$blueColor: #0C22D5;
$grayColor: #7E7E7E;
