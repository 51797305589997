@import "mixins";

// Font size util classes
.fs-12 { font-size: pxToRem(12px); }
.fs-13 { font-size: pxToRem(13px); }
.fs-14 { font-size: pxToRem(14px); }
.fs-15 { font-size: pxToRem(15px); }
.fs-16 { font-size: pxToRem(16px); }
.fs-17 { font-size: pxToRem(17px); }
.fs-18 { font-size: pxToRem(18px); }
.fs-19 { font-size: pxToRem(19px); }
.fs-20 { font-size: pxToRem(20px); }
.fs-21 { font-size: pxToRem(21px); }
.fs-22 { font-size: pxToRem(22px); }
.fs-23 { font-size: pxToRem(23px); }
.fs-24 { font-size: pxToRem(24px); }
.fs-25 { font-size: pxToRem(25px); }
.fs-26 { font-size: pxToRem(26px); }
.fs-27 { font-size: pxToRem(27px); }
.fs-28 { font-size: pxToRem(28px); }
.fs-29 { font-size: pxToRem(29px); }
.fs-30 { font-size: pxToRem(30px); }
.fs-31 { font-size: pxToRem(31px); }
.fs-32 { font-size: pxToRem(32px); }

// Font weight util classes
.fontWeight-600 {
  font-weight: 600;
}

.fontWeight-500 {
  font-weight: 500;
}

.fontWeight-300 {
  font-weight: 300;
}

.cursorPointer {
  cursor: pointer;
}

.cursorNotAllowed {
  cursor: not-allowed;
}

.spacingNormal {
  letter-spacing: normal !important;
}

.rotate-0 {
  transform: rotate(0deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.expanded {
  flex-grow: 1;
}

.text-gray {
  color: $grayColor;
}
