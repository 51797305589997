@import "variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@function pxToRem($value){
  @return ($value / $defaultFontSize) + rem
}

@mixin container {
  width: pxToRem(850px);
  padding: pxToRem(70px) 0;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1200px) and (max-width: 1500px) {
    width: 100%;
    padding: pxToRem(70px) pxToRem(50px);
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    padding: pxToRem(40px) pxToRem(20px);
  }
}

@mixin fullPageContainer {
  @include container;
  padding: pxToRem(70px) pxToRem(55px);
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  @include media-breakpoint-down(lg) {
    padding: pxToRem(70px) pxToRem(20px)
  }
}

@mixin btnPseudoClasses {
  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }

  &.disabled {
    opacity: 0.3;
  }

  @include  media-breakpoint-down(lg) {
    &:active, &:hover {
      opacity: 1;
    }
  }
}

@mixin wrapExtendDivs {
  flex-wrap: wrap;

  div {
    width: 100%;
  }
}
